
import { defineComponent, computed, reactive } from "vue";

import CategoryMultiSelect from "@/components/category/CategoryMultiSelect.vue";
import ProductMultiSelect from "@/components/product/ProductMultiSelect.vue";
import { useProducts, useProductsFilter } from "@/graphql/product/products";
import {
  CONSTANTS,
  extractNumber,
  formatNumber,
  getPrices,
  getPurchasePrices,
  sumStock,
} from "@/graphql/utils/utils";
import { printRapport } from "@/components/rapport/printer";
import { useI18n } from "vue-i18n";
import { Category, Product } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";

type StockRow = Product & {
  gap: number;
  stock: number;
  category: Category;
  sellingPrices: string;
  totalSellingPrices: string;
  purchasePrices: string;
  totalPurchasePrices: string;
};

const defaultTotals = {
  stock: 0,
  totalSellingPrices: 0,
  totalPurchasePrices: 0,
};

export default defineComponent({
  name: "ProductStockRapport",
  components: {
    CategoryMultiSelect,
    ProductMultiSelect,
  },
  setup() {
    const { products, loading } = useProducts();
    const { filters, clearFilter, priceMatchModeOptions } = useProductsFilter();
    const { t, d, n, tm } = useI18n();
    const resume = computed(() => {
      const storable: StockRow[] = [];
      const categories = [];
      products.value.forEach((product) => {
        if (!product.notStorable) {
          const category = { ...product.category };
          if (categories.findIndex((c) => c.id == category.id) < 0) {
            Object.assign(category, {
              label:
                tm("rapport.categories")[category.id - 1] || category.label,
            });
            categories.push(category);
          }
          const stock = sumStock(product.stocks);
          storable.push({
            ...product,
            stock,
            gap: stock - product.threshold,
            category: category,
            sellingPrices: getPrices(product),
            totalSellingPrices: getPrices(product, true),
            purchasePrices: getPurchasePrices(product),
            totalPurchasePrices: getPurchasePrices(product, true),
            createdAt: new Date(product.createdAt),
          });
        }
      });
      return { storable, categories };
    });

    function print() {
      printRapport(`<table>
        <thead>
          <tr>
            <th class="p-text-uppercase" colspan="10">
              <h2 class="p-pt-3">${t("rapport.tab3")}</h2>
            </th>
          </tr>
          <tr>
            <th colspan="10">${t("date")} : ${d(new Date(), "long")}</th>
          </tr>
          <tr>
            <th class="p-text-left">${t("product.reference")}</th>
            <th class="p-text-center">${t("product.product")}</th>
            <th>${t("product.category")}</th>
            <th>${t("product.purchasePrice")}</th>
            <th>${t("product.sellingPrice")}</th>
            <th>${t("product.totalPurchasePrice")}</th>
            <th>${t("product.totalSellingPrice")}</th>
            <th class="p-text-center">
              ${t("stock.stock")}
            </th>
            <th class="p-text-right">
              ${t("product.threshold")}
            </th>
           <th class="p-text-right">
              ${t("stock.gap")}
            </th>
          </tr>
        </thead>
        <tbody>
          ${resume.value.storable
            .map(
              (data) => `<tr>
            <td class="p-text-left">
              ${data.reference}
            </td>
            <td class="p-text-center">
              ${data.name}
            </td>
            <td>${data.category.label}</td>
            <td>${data.purchasePrices}</td>
            <td>${data.sellingPrices}</td>
            <td>${data.totalPurchasePrices}</td>
            <td>${data.totalSellingPrices}</td>
            <td class="p-text-center">
              ${formatNumber(data.stock)}
            </td>
            <td class="p-text-center">
              ${formatNumber(data.threshold)}
            </td>
           <td class="p-text-right">
              ${formatNumber(data.gap)}
            </td>
          </tr>`
            )
            .join("")}
        </tbody>
        <tfoot>
          <tr>
            <th colspan="5">${t("payment.total")}</th>
            <th>${formatNumber(filteredTotals.totalPurchasePrices)} ${
        activeActivity.value.currencySymbol
      }</th>

            <th>${formatNumber(filteredTotals.totalSellingPrices)} ${
        activeActivity.value.currencySymbol
      }</th>

            <th colspan="3">${formatNumber(filteredTotals.stock)}</th>
          </tr>
        </tfoot>
      </table>`);
    }

    const filteredTotals = reactive({
      ...defaultTotals,
    });

    const calculPrice = (p: string) =>
      p.split(" / ").reduce((cum, cur) => cum + extractNumber(cur), 0);

    function onFilter(event: any) {
      Object.assign(filteredTotals, defaultTotals);
      (event.filteredValue as StockRow[]).forEach((row) => {
        filteredTotals.stock += row.stock;
        filteredTotals.totalSellingPrices += extractNumber(
          row.totalSellingPrices
        );
        filteredTotals.totalPurchasePrices += extractNumber(
          row.totalPurchasePrices
        );
      });
    }

    return {
      filters,
      clearFilter,
      loading,
      sumStock,
      print,
      resume,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
      currencySymbol: activeActivity.value.currencySymbol,
      formatNumber,
      priceMatchModeOptions,
      onFilter,
      filteredTotals,
    };
  },
});
