
import { categoryParams } from "@/graphql/utils/product";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CategoryMultiSelect",
  props: ["items", "modelValue", "loading", "placeholder"],
  emits: ["update:modelValue"],
  setup() {
    return {
      categoryParams,
    };
  },
});
