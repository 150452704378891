
import { defineComponent } from "vue";
import { Product } from "@/graphql/types";
import { productParams } from "@/graphql/utils/product";

export default defineComponent({
  name: "ProductMultiSelect",
  props: ["items", "modelValue", "loading", "placeholder"],
  emits: ["update:modelValue", "search"],
  setup(props: { items: Product[] }, { emit }) {
    const filter = (name: string) => {
      name = name.toLowerCase();
      if (name) {
        const index = props.items.findIndex((p: Product) =>
          p.name.toLowerCase().startsWith(name)
        );
        if (index < 0) emit("search", name);
      } else emit("search", name);
    };
    return {
      filter,
      productParams,
    };
  },
});
